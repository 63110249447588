<template>
  <div id="app">
    <router-view />
    <div v-if="showPopUp" class="popup">
      <div class="container-popup">
        <div class="text-popup uppercase">
          <h1>{{ $t("message.Attenzione") }}!</h1>
          <p>
            {{
              $t("message.A_causa_del_potenziamento_dei_nostri_sistemi,_garantiamo_l*evasione_degli_ordini_effettuati_entro_il_12_dicembre**_Tutti_gli_ordini_ricevuti_successivamente,_saranno_presi_in_carico_senza_alcuna_omissione**_Le_spedizioni_riprenderanno_regolarmente_dal_07/01,_quando_torneremo_alla_piena_operatività_con_un_sistema_ancora_più_efficiente,_pensato_per_servirti_il_meglio**")
            }}          </p>
        </div>
        <div class="button-popup">
          <div class="checkbox-popup">
            <label>
              <input type="checkbox" v-model="dontShowAgain" /> Non mostrare più
            </label>
          </div>
          <div class="buttons">
            <button @click="closePopUp">Chiudi</button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isInDateRange" class="button-alert-popup uppercase" @click="changePopUp">
      <div>{{ $t("message.Avviso") }} !</div>
    </div>
  </div>
</template>


<script>
// eslint-disable-next-line no-unused-vars
import { mapState } from "vuex";

export default {
  // store,
  name: "App",
  // components: {
  //   Configurator,
  //   // Canvas3d: this.$canvas3d,
  // },
  data() {
    return {
      showPopUp: false,
      dontShowAgain: false,
      isInDateRange: false,
      country: "",
    };
  },
  mounted: async function () {
    let cc = await this.$rest.getCountryCode();
    this.country = cc.data;
    const dontShow = localStorage.getItem("dontShow");
    const startDateEstero = new Date("2024-12-12"); // Inizio intervallo
    const startDate = new Date("2024-12-01"); // Inizio intervallo
    const endDate = new Date("2025-01-07"); // Fine intervallo
    const currentDate = new Date();
    this.isInDateRange = currentDate >= startDate && currentDate <= endDate;
    if (this.country === "IT") {
      this.isInDateRange = currentDate >= startDate && currentDate <= endDate;
    } else {
      this.isInDateRange =
        currentDate >= startDateEstero && currentDate <= endDate;
    }

    if (this.isInDateRange && (!dontShow || dontShow === "false")) {
      this.showPopUp = true;
    }

    // eslint-disable-next-line no-undef
    gtag('config', this.gtagId, {
      'custom_map': {
        'dimension1': 'aste',
        'dimension2': 'coloreMetalloAste',
        'dimension3': 'finitura',
        'dimension4': 'asianFitting',
        'dimension5': 'coloreFrontale',
        'dimension6': 'misura',
        'dimension7': 'finituraAste',
        'dimension8': 'nomePersonalizzato',
        'dimension9': 'coloreNomePersonalizzato',
      }
    })
  },
  computed: {
    ...mapState(["gtagId"]),
  },
  methods: {
    closePopUp() {
      // Salva la preferenza dell'utente nel localStorage
      if (this.dontShowAgain) {
        localStorage.setItem("dontShow", "true");
      }
      this.showPopUp = false;
    },
    changePopUp() {
      this.showPopUp = !this.showPopUp; // Cambia lo stato del popup
    },
  },
  // // watch: {
  // //   items(newVal) {
  // //     console.log(newVal, "ccccccccccccccccccccccccccccccccc");
  // //     // this.setFrontal(newVal[15]);
  // //     // this.$store.state.configurator.frontale = newVal[0];
  // //   },
  // // },
};
</script>


<style lang="less">
#app {
  position: relative;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100vh;
  height: 100dvh;

  .uppercase {
    text-transform: uppercase;
  }

  .popup {
    z-index: 1000;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 35%;
    height: 25%;
    background-color: black;
    color: white;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);

    .container-popup {
      display: flex;
      gap: 1em;
      flex-direction: column;
      justify-content: space-between;

      .text-popup {
        h1 {
          margin: 0;
          color: red;
        }

        p {
          margin: 10px 0;
        }
      }

      .button-popup {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        gap: 2em;

        .checkbox-popup {
          cursor: pointer;

          label {
            color: white;
            font-size: 14px;
          }
        }

        .buttons {
          button {
            padding: 10px 20px;
            border: none;
            background-color: #e74c3c;
            color: white;
            font-size: 16px;
            cursor: pointer;
            border-radius: 5px;
            transition: background-color 0.3s;
          }

          button:hover {
            background-color: #c0392b;
          }
        }
      }
    }
  }

  .button-alert-popup {
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
    background-color: red;
    color: white;
    padding: 10px;
    display: flex;
    gap: 1em;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 1%;
    right: 50%;
    translate: 50%;
    cursor: pointer;
  }
}

@media screen and (max-width: 1000px) {
  #app .popup {
    width: 80%;
    height: 60%;

    .container-popup {
      .text-popup {
        h1 {
          font-size: 1.5em;
        }

        p {
          font-size: 1em;
        }
      }

      .button-popup {
        flex-direction: column;
      }
    }

  }

  .button-alert-popup {
    translate: 50%;
  }


}
</style>
